@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.wrapper1 {
    background: #16394f;
    padding: .5rem 0;
    margin: 2rem 0;
    border-bottom: 5px solid #791404af;
    border-top: 5px solid #791404af;
}

.wrapper-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.whatWeDo-header {
    text-align: center;
    padding: 1rem;
}

.wrapper-content-left {
    width: 50%;
    text-align: center;
}

.wrapper-content-left img {
    width: 80%;
    border-radius: 1rem;
}

.wrapper-content-right {
    width: 600px;
    margin: auto;
    padding: 0 2rem;
}



.whatWeDo-header h1 {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: 1rem;
    letter-spacing: 8px;
    padding: .5rem 0; 
}

.whatWeDo-header p {
    text-align: center;
    font-size: 14px;
    color: white;
}



@media screen and (max-width:1250px)  {
    .wrapper-content-right {
        width: 100%;
        padding: 1rem 2rem;
    }

    .wrapper-content-right h1, .wrapper-content-right h2, .wrapper-content-right p {
        text-align: center;
    }

    .wrapper-content-right h2 {
        font-size: 2rem;
    }

}

@media screen and (max-width:940px) {
    .wrapper-content-left {
        width: 100vw;
    }
    .wrapper-content-left img {
        padding: 2rem;
    }
}

