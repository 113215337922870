@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

@font-face {
  font-family: "armalite";
  src: local("armalite"),
   url("../fonts/armalite.ttf") format("truetype");
  font-weight: bold;
 }

/* img tags */

.imagesContainer {
	width: 100%;
	height: 100dvh;
	position: relative;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.356) 10px 10px 10px;
}


.hero-caption {
  position: absolute;
  z-index: 5;
  bottom: 20%;
  left: 5%;
  padding: 1rem;
  width: 60%;
}


.hero-caption h1 {
  color: rgb(255, 255, 255);
  font-size: 5rem;
  text-transform: uppercase;
  font-family: "armalite";
  font-weight: normal;
}

.hero-caption p {
  color: white;
  font-size: 1rem;
}

.hero-caption h3 {
  color: white;
  font-size: 2rem;
  padding: .5rem 0;
  font-family: 'Playfair Display', serif;
  font-weight: normal;
}


.hero-caption h2 {
  color: white;
  font-size: .8rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: .3rem 0 0 0;
  font-weight: normal;
}

.imagesContainer img {
	width: 100%;
  height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

/* images using CSS */
.imageDiv {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
}

.image1 { background: linear-gradient(to top, #0000005d, #0000005d), url('../../assets/Hero1.webp')no-repeat center top/cover; }

.image2 { background: linear-gradient(to top, #0000005d, #0000005d), url('../../assets/Hero2.webp')no-repeat center top/cover; }


/* animation styles */
@keyframes fadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	60% { opacity: 1; }
	100% { opacity: 1; }
}

.fadeInClass {
	animation-name: fadeIn;
	animation-duration: 7s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;
}


.hero-btn button {
  background-color: #16394f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: .8rem 2.5rem;
  font-size: 1rem;
  letter-spacing: 3px;
}

.hero-btn button:hover {
  background-color: #16394fbd;
}


@media screen and (max-width: 940px) {
  .hero-caption {
    width: 100%;
    bottom: 10%;
    left: 0%;
    text-align: center;
  }

  .hero-caption h1 {
    font-size: 3.5rem;
    line-height: 3rem;
  }

  .hero-caption h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .hero-caption h1 {
    font-size: 3rem;
    line-height: 3rem;
  }


  .hero-caption h2 {
    font-size: .8rem;
  }
}