@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.PanicDisorder {
    width: 100%;
    height: 80vh;
    background: #05050581;
    position: relative;
  }
  
  .PanicDisorder:before {
    content: '';
    position: absolute;
    background: url('../../assets/mental-health-submenu-bg.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.PanicDisorder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.PanicDisorder .PanicDisorder-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}

.PanicDisorder h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.PanicDisorder h3 {
    color: white;
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.PanicDisorder h1 {
    font-size: 2rem;
}


}