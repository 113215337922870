@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.oxycodone-section-header {
  width: auto;
  padding: 20px;
  text-align: center;
  margin: 2rem auto;
}

.oxycodone-section-header h1 {
font-family: 'roboto', sans-serif;
  text-align: left;
  font-weight: 300;
}

.oxycodone-section-header p {
font-family: 'roboto', sans-serif;
text-align: justify;
font-size: 15px;
}



