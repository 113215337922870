@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.alcohol-section-header {
    width: auto;
    padding: 20px;
    text-align: center;
    margin: 2rem auto;
}

.alcohol-section-header h1 {
  font-family: 'roboto', sans-serif;
    text-align: left;
    font-weight: normal;   
    color: rgb(216, 149, 4);
    text-transform: uppercase;
    font-size: 1.5rem;
    
}

.alcohol-section-header p {
  font-family: 'roboto', sans-serif;
  text-align: justify;
  font-size: 16px;
}


@media screen and (max-width: 940px) {
  .alcohol-section-header h1 {
    text-align: center;
    font-size: 1.2rem;
}
}