

.homeSection2-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 5rem 1rem;
}



.homeSection2-right {
    width: 45%;
    margin: auto;
}

.homeSection2-right img {
    width: 100%;
    border-radius: .5rem;
}

.homeSection2-left {
    width: 50%;
    margin: auto;
}

.homeSection2-content h1 {
    font-size: 1rem;
    padding: .5rem 0;
    letter-spacing: 5px;
    color: var(--secondary-color);
}

.homeSection2-content h2 {
    font-size: 1.2rem;
    color: #011803;
}

.homeSection2-content p {
    font-size: 15px;
    text-align: justify;
    color: rgb(0, 0, 0);
    margin-bottom: 0;
    padding: .5rem 0;
    font-family: 'roboto';
}


.checklist {
    display: flex;
    gap: 1rem;
}

.homeSection2-icon {
    display: flex;
    font-size: 1rem;
    color: var(--secondary-dark);
    padding: .2rem 0;
}

.homeSection2-icon p {
    padding: 0 .5rem;
}


@media screen and (max-width:940px) {



    .homeSection2-content {
        flex-wrap: wrap;
        padding: 1rem;
    }

    .homeSection2-content h1 {
        text-align: center;
    }

    .homeSection2-content h2 {
        text-align: center;
    }

    .homeSection2 {
        margin: 1rem 0 7rem  0;
    }

    .homeSection2-right {
        width: 100%;
        margin: auto;
    } 

    .homeSection2-left {
        width: 100%;
    }

    .homeSection2-content p {
        font-size: 14px;
    }
    
}