@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: 120px;
    position: absolute;
    z-index: 10;
    background: #16394f;
}

.navbar .navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;

}

.navbar-logo {
  width: 300px;
  padding: 1rem;
  z-index: 2;
}

.navbar-logo img {
  width: 100%;
}

.navbar .icon {
  font-size: 1.4rem;
  color: white;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
}

.navbar li {
    font-size: 13px;
    width: max-content;
    padding: .5rem .8rem;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.navbar li a {
  font-weight: 300;
  color: #fff;
}

  .menu-item .sub__menus li a {
    color: #f3ebeb;
    font-size: 13px;
    padding: -1rem ;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(255, 123, 0) !important;
  }

/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #16394f;
    border-radius: 10px;
    width: 240px;
    left: -17px;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }

  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 10px;
  }


  
@media screen and (max-width: 1200px) {
    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 70%;
        height: 100vh;
        top: 0;
        left:-100%;
        justify-content: center;
        background: #584102;
        transition: 0.5s ease-in;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
        width: 100%;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.247);
    }

    .navbar button {
        font-size: 1.8rem;
    }


    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        padding: .2rem 1.5rem;
      }

      .sub__menus {
        position: absolute;
        background: #16394f;
        border-radius: 0px;
        width: 100%;
        margin-top: 6px;
        z-index: 1000;
        left: 0px;
      }

      .navbar .navbar-container {
        justify-content: space-between;
      }

}





