@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.footer {
    width: 100%;
    height: auto;
    position: relative;
    background: var(--primary-dark)
}

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
    width: 300px;
}

.logo-footer img {
    padding: 1rem;
    width: 100%;
    border-radius: 5px;
}

.footer .top .footer-icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
}


.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
}

.col {
    width: 300px;
}

.footer .col  h3 {
    color: var(--primary-color);
    font-size: 1rem;
    margin: 1rem 0;
    text-transform: uppercase;
    text-align: center;
}

.footer .col p {
    font-family: 'Montserrat', sans-serif;
    margin: .5rem 0;
    color: white;
    font-weight: 300;
    text-align: center;
}

.footer form {
    width: 100%;
    margin-bottom: .6rem;
    position: relative;
}

.footer form input {
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer form input:focus {
    color: #333;
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-dark);
}

.footer form .mail-icon {
    position: absolute;
    font-size: 1.4rem;
    margin: 1rem;
    right: .2rem;
    top: 3.8rem;
    color: #aaa;
    cursor: pointer;
}

.footer form .social-icon {
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}

    .footer {
        height: 100%;
    }


    .copyright p {
        color: white;
        text-transform: uppercase;
        text-align: center;
        margin: auto;
        text-align: center;
        padding: 1rem 0;
    }

    .certification {
        width: 100%;
        text-align: center;
        margin: auto;
    
    }

    .certification h3 {
        text-align: center;
        font-size: 14px;
        color: white;
        padding: 1rem 0;
        font-weight: normal;
    }
    
    .certification p {
        text-align: center;
        color: white;
    }
    
    .certificationLeftImage {
        width: 200px;
        margin: auto;
    }
    
    .certificationLeftImage img{
        width: 100%;
    }
    
    
    
    .certification a:hover {
        text-align: center;
        color: rgb(10, 208, 235);
    }
    

    @media  screen and (max-width:940px) {
         /* .footer-icon {
            display: none;
        } */
    }

    
    @media  screen and (max-width:500px) {
        .col {
            width: 100%;
        }
        .footer p, .footer h3 {
            text-align: center;
        }
     }
